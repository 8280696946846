import { RestModule } from '../RestModule.class';
import { User, NewUser } from '../../Models/user.model';
import { ApiResponse } from '../../Models/apiResponse.model';

export class Users extends RestModule<User, NewUser> {
  constructor() {
    super('users');
  }

  public async self(): Promise<ApiResponse<User>> {
    const endpoint = `/${this.endpoint}/self`;

    return this.request<User>('get', endpoint);
  }
}
