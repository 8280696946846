import { Module } from '../Module.class';
import { PostCode } from '../../Models/postCode.model';
import { ApiResponse } from '../../Models/apiResponse.model';

export class PostalCodes extends Module {
  constructor() {
    super();
  }

  public async get(
    postalCode: string,
    number: string,
  ): Promise<ApiResponse<PostCode>> {
    return await this.client
      .get<
        ApiResponse<PostCode>
      >(`/postcode?postcode=${postalCode}&number=${number}`)
      .then((res) => {
        return res.data;
      });
  }
}
