import {
  FormQuestion,
  NewFormQuestion,
} from '../../Models/form/formQuestion.model';
import { RestModule } from '../RestModule.class';

export class FormQuestions extends RestModule<FormQuestion, NewFormQuestion> {
  constructor() {
    super('form-questions');
  }
}
