import { defineStore } from 'pinia';
import type { User } from '@briefcase-app/briefcase-api-sdk';
import { BriefcaseAPISDK } from '@briefcase-app/briefcase-api-sdk';
import flagsmith from 'flagsmith';
import type { IFlags } from 'flagsmith/types';

type RootState = {
  authToken: string | null;
  impersonationToken: string | null;
  user: User | null;
  permissions: string[];
  flags: IFlags<string>;
};

const defaultState = {
  authToken: null,
  impersonationToken: null,
  user: null,
  permissions: [],
  flags: null,
} as RootState;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => defaultState,
  actions: {
    setAuthToken(token: string) {
      this.authToken = token;
      this.getUser();
    },
    clear() {
      this.authToken = null;
      this.user = null;
      this.permissions = [];

      flagsmith.identify(null);
    },
    async getUser() {
      const briefcase = new BriefcaseAPISDK();
      briefcase.users.self().then((res) => {
        if (res.status === 'success') {
          this.user = res.data;
          flagsmith.identify(this.user.id);
        }
      });
    },
    hasFeature(feature: string) {
      return this.flags?.[feature]?.enabled === true;
    },
    getFeatureValue(feature: string) {
      return this.flags?.[feature]?.value;
    },
    async setImpersonationToken(token: string | null) {
      this.user = null;
      this.impersonationToken = token;
      await this.getUser();
      location.reload();
    },
    async impersonate(userId: string) {
      const briefcase = new BriefcaseAPISDK();
      briefcase.auth.impersonate(userId).then(async (res) => {
        if (res.status === 'success') {
          this.setImpersonationToken(res.data.token);
        }
      });
    },
    stopImpersonation() {
      const briefcase = new BriefcaseAPISDK();
      briefcase.auth
        .stopImpersonation(this.user.id, this.authToken)
        .then((res) => {
          if (res.status === 'success') {
            this.setImpersonationToken(null);
          }
        });
    },
  },
  persist: true,
});

export default useAuthStore;
