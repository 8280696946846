import { fakeResponse } from '../../Helpers/ApiResponse';
import { SuccessResponse } from '../../Models/apiResponse.model';
import { Module } from '../Module.class';

export class Auth extends Module {
  constructor() {
    super();
  }

  /**
   * Login with email and password.
   * @param email User's email address
   * @param password User's password
   * @returns The user data and token
   */
  async login(
    email: string,
    password: string,
  ): Promise<SuccessResponse<{ token: string }>> {
    const response = await this.client.post('/auth/login', { email, password });
    this.setAuthToken(response.data.data.token);

    return response.data;
  }

  /**
   * Logout the user and clear the authentication token.
   * @returns Success message
   */
  async logout(): Promise<SuccessResponse<null>> {
    this.setAuthToken(null);
    return fakeResponse('Logged out successfully');
  }

  /**
   * Check if the user is logged in.
   */
  isLoggedIn(): boolean {
    return !!this.client.defaults.headers.common['Authorization'];
  }

  /**
   * Set or unset the authentication token in the AxiosClient instance.
   * @param token Authentication token or null to clear the token
   */
  public setAuthToken(token: string | null): void {
    if (token) {
      this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.client.defaults.headers.common['Authorization'];
    }
  }

  public async impersonate(
    userId: string,
  ): Promise<SuccessResponse<{ token: string }>> {
    const response = await this.client.post(`/auth/impersonate/${userId}`);
    this.setAuthToken(response.data.data.token);

    return response.data;
  }

  public async stopImpersonation(
    userId: string,
    originalAccessToken: string,
  ): Promise<SuccessResponse<null>> {
    const response = await this.client.delete(`/auth/impersonate/${userId}`);
    this.setAuthToken(originalAccessToken);

    return response.data;
  }
}
