import { RestModule } from '../RestModule.class';
import {
  LegalEmployee,
  NewLegalEmployee,
} from '../../Models/legalCompany/legalEmployee.model';

export class LegalEmployees extends RestModule<
  LegalEmployee,
  NewLegalEmployee
> {
  constructor() {
    super('legal-employees');
  }
}
