import { SuccessResponse } from '../Models/apiResponse.model';

export const fakeResponse = (msg: string): SuccessResponse<null> => {
  return {
    status: 'success',
    data: null,
    meta: {
      message: msg,
    },
  };
};
