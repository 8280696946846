import AxiosClient from '../Helpers/AxiosClient';
import { AxiosInstance } from 'axios';

export abstract class Module {
  protected client: AxiosInstance;

  protected constructor() {
    this.client = AxiosClient.getInstance().client;
  }
}
