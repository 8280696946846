<script setup lang="ts">
import Spinner from './Spinner.vue';
import { type FunctionalComponent } from 'vue';

type ButtonStyle = 'neutral' | 'transparent' | 'icon' | 'error';

withDefaults(
  defineProps<{
    style?: ButtonStyle;
    disabled?: boolean;
    loading?: boolean;
    leftIcon?: FunctionalComponent | null;
    rightIcon?: FunctionalComponent | null;
  }>(),
  {
    style: 'neutral',
    disabled: false,
    loading: false,
    leftIcon: null,
    rightIcon: null,
  },
);

const defaultPadding = 'py-3 px-4';
const defaultFocus =
  'focus:border-neutral-900 focus:ring-1 focus:ring-neutral-900';
const defaultShadow = 'shadow-sm';

const styles = {
  neutral: {
    default: [
      'text-neutral-900 bg-white border-neutral-200',
      defaultPadding,
      defaultShadow,
    ],
    enabled: ['hover:bg-neutral-100', defaultFocus],
    disabled: 'text-neutral-500 bg-neutral-50 border-neutral-200',
  },
  transparent: {
    default: [
      'text-neutral-900 bg-transparent border-transparent',
      defaultPadding,
    ],
    enabled: ['hover:bg-neutral-100 hover:border-neutral-100', defaultFocus],
    disabled: 'opacity-50',
  },
  error: {
    default: [
      'text-white bg-red-600 border-red-600',
      defaultPadding,
      defaultShadow,
    ],
    enabled:
      'hover:bg-red-700 focus:border-red-800 focus:ring-1 focus:ring-red-800',
    disabled: 'text-red-500 bg-red-50 border-red-200',
  },
  icon: {
    default: ['text-neutral-400 bg-transparent border-transparent'],
    enabled: 'hover:text-neutral-900 focus:text-neutral-900',
    disabled: 'opacity-50',
  },
};

const emit = defineEmits(['click']);
</script>

<template>
  <button
    @click="() => (!disabled ? emit('click') : () => {})"
    class="relative flex flex-row items-center gap-1.5 overflow-clip rounded-md border text-sm font-semibold leading-4 outline-none transition-all duration-75 ease-in-out"
    :class="[
      styles[style].default,
      !disabled
        ? [styles[style].enabled]
        : ['opacity-40', styles[style].disabled],
      !(disabled || loading) ? 'cursor-pointer' : '',
      `btn--${style}`,
    ]"
    :disabled="disabled || loading"
  >
    <span
      v-if="loading && !(style === 'icon')"
      class="absolute left-0 z-[1] flex h-full w-full items-center justify-center bg-neutral-100 opacity-95"
    >
      <Spinner class="text-neutral-500" />
    </span>
    <component v-if="leftIcon" :is="leftIcon" class="btn-icon" />
    <slot></slot>
    <component v-if="rightIcon" :is="rightIcon" class="btn-icon" />
  </button>
</template>

<style lang="postcss">
.btn-icon {
  @apply h-4 w-4;
  color: currentColor;
}
</style>
