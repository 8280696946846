<script setup lang="ts"></script>

<template>
  <aside
    class="sidebar flex max-h-screen w-1/4 min-w-[13rem] max-w-[16rem] flex-shrink-0 flex-col justify-start gap-6 overflow-y-auto border-r border-neutral-800 bg-neutral-900 p-4"
  >
    <slot name="header"></slot>

    <slot name="main"></slot>

    <slot name="footer"></slot>
  </aside>
</template>
