<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import {
  ArrowLeftEndOnRectangleIcon,
  FingerPrintIcon,
} from '@heroicons/vue/20/solid';
import {
  EllipsisVerticalIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline';
import { RouterLink } from 'vue-router';
import useAuthStore from '../../Stores/AuthStore';
import useBriefcase from '../../Helpers/useBriefcase';
import router from '../../Helpers/router';

const authStore = useAuthStore();
const briefcase = useBriefcase();

const logout = () => {
  briefcase.auth.logout();
  authStore.clear();
  router.push({ name: 'login' });
};
</script>

<template>
  <div class="flex h-full flex-shrink-0 cursor-pointer items-center text-right">
    <Menu as="div" class="relative inline-block items-center text-left">
      <div class="flex">
        <MenuButton
          class="inline-flex w-full justify-center rounded-md text-gray-400"
        >
          <EllipsisVerticalIcon class="h-5 w-5" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-0 mt-3 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <!--          @TODO Uncomment when ready -->
          <!--          <div class="px-1 py-1">-->
          <!--            <MenuItem v-slot="{ active }">-->
          <!--              <RouterLink-->
          <!--                :to="'/account'"-->
          <!--                :class="[-->
          <!--                  active ? 'bg-black text-white' : 'text-gray-900',-->
          <!--                  'group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium transition-all',-->
          <!--                ]"-->
          <!--              >-->
          <!--                <UserCircleIcon class="mr-2 h-5 w-5" />-->
          <!--                Mijn account-->
          <!--              </RouterLink>-->
          <!--            </MenuItem>-->
          <!--          </div>-->
          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }" v-if="!authStore.impersonationToken">
              <button
                :class="[
                  active ? 'bg-black text-white' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium transition-all',
                ]"
                @click="logout"
              >
                <ArrowLeftEndOnRectangleIcon class="mr-2 h-4 w-4" />
                Uitloggen
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }" v-else>
              <button
                :class="[
                  active ? 'bg-black text-white' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium transition-all',
                ]"
                @click="authStore.stopImpersonation()"
              >
                <FingerPrintIcon class="mr-2 h-4 w-4" />
                Eigen account
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
