import {
  LegalAssociation,
  NewLegalAssociation,
} from '../../Models/legalCompany/legalAssociation.model';
import { RestModule } from '../RestModule.class';

export class LegalAssociations extends RestModule<
  LegalAssociation,
  NewLegalAssociation
> {
  constructor() {
    super('legal-associations');
  }
}
