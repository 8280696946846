import { RestModule } from './RestModule.class';
import { ApiResponse } from '../Models/apiResponse.model';
import { EmailAddress, NewEmailAddress } from '../Models/emailAddress.model';

export class EmailAddresses extends RestModule<EmailAddress, NewEmailAddress> {
  constructor() {
    super('email-addresses');
  }

  public async setPrimary(id: number): Promise<ApiResponse<EmailAddress>> {
    return this.update(id, { is_primary: true });
  }
}
