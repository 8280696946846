import Gleap from 'gleap';

const useGleap = () => {
  Gleap.initialize('clW9My4XYH0NXU0ahN7CIWFrKeF8XEGY');

  const gleapEnv = () => {
    switch (import.meta.env.MODE) {
      case 'development':
        return 'dev';
      case 'production':
        return 'prod';
      case 'staging':
        return 'staging';
      default:
        return 'dev';
    }
  };

  Gleap.setLanguage('nl-NL');
  Gleap.setEnvironment(gleapEnv());
};

export default useGleap;
