import { RestModule } from './RestModule.class';
import { Address, NewAddress } from '../Models/address.model';
import { ApiResponse } from '../Models/apiResponse.model';

export class Addresses extends RestModule<Address, NewAddress> {
  constructor() {
    super('addresses');
  }

  public async setPrimary(id: number): Promise<ApiResponse<Address>> {
    return this.update(id, { is_primary: true });
  }
}
