import 'vue-router';
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import { useAuthStore } from '../Stores/AuthStore';
import useSharedStore from '../Stores/SharedStore';

const HomePage = () => import('../Pages/HomePage.vue');
const Login = () => import('../Pages/Auth/Login.vue');
const ClientPage = () => import('../Pages/ClientPage.vue');
const ClientsPage = () => import('../Pages/ClientsPage.vue');
const LawyerPage = () => import('../Pages/LawyerPage.vue');
const LegalCompaniesPage = () => import('../Pages/LegalCompaniesPage.vue');
const LegalCompanyPage = () => import('../Pages/LegalCompanyPage.vue');
const LegalEmployeesPage = () => import('../Pages/LegalEmployeesPage.vue');
const LegalEmployeePage = () => import('../Pages/LegalEmployeePage.vue');
const SettingsPage = () => import('../Pages/SettingsPage.vue');
const FormEditPage = () => import('../Pages/Settings/FormEditPage.vue');
const AccountPage = () => import('../Pages/AccountPage.vue');
const FormPage = () => import('../Pages/Settings/FormPage.vue');
const LegalAreaPage = () => import('../Pages/Settings/LegalAreaPage.vue');
const LegalAssociationsPage = () =>
  import('../Pages/Settings/LegalAssociationsPage.vue');
const UsersPage = () => import('../Pages/Settings/UsersPage.vue');
const CaseTypePage = () => import('../Pages/Settings/CaseTypePage.vue');
const CasePage = () => import('../Pages/CasePage.vue');
const CasesPage = () => import('../Pages/CasesPage.vue');
const IncomeStandardsPage = () =>
  import('../Pages/Settings/IncomeStandardsPage.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: HomePage,
    name: 'home',
    meta: { title: 'Takenlijst' },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: { hideSidebar: true, title: 'Inloggen' },
  },
  {
    path: '/klanten',
    component: ClientsPage,
    name: 'clients',
    meta: { title: 'Klanten' },
  },
  {
    path: '/klanten/:clientId',
    component: ClientPage,
    name: 'client',
    children: [
      {
        path: '',
        component: CasesPage,
        name: 'cases',
        meta: { title: 'Cases' },
      },
      {
        path: ':caseId',
        component: CasePage,
        name: 'case',
        meta: { title: 'Case' },
      },
    ],
    meta: { title: 'Klant' },
  },
  { path: '/advocaten', component: LawyerPage, meta: { title: 'Advocaten' } },
  {
    path: '/kantoren',
    component: LegalCompaniesPage,
    name: 'legalCompanies',
    meta: { title: 'Kantoren' },
  },
  {
    path: '/kantoren/:legalCompanyId',
    component: LegalCompanyPage,
    name: 'legalCompany',
    children: [
      {
        path: '',
        component: LegalEmployeesPage,
        name: 'legalEmployees',
        meta: { title: 'Advocaten' },
      },
      {
        path: ':legalEmployeeId',
        component: LegalEmployeePage,
        name: 'legalEmployee',
        meta: { title: 'Advocaat' },
      },
    ],
    beforeEnter: () => {
      const shared = useSharedStore();
      shared.currentCompany = null;
    },
    meta: { title: 'Kantoor' },
  },
  {
    path: '/instellingen',
    component: SettingsPage,
    name: 'settings',
    meta: { title: 'Instellingen' },
    children: [
      {
        path: 'formulieren',
        component: FormPage,
        meta: { title: 'Formulieren' },
      },
      {
        path: 'rechtsgebieden',
        component: LegalAreaPage,
        meta: { title: 'Rechtsgebieden' },
      },
      {
        path: 'gebruikers',
        component: UsersPage,
        meta: { title: 'Gebruikers' },
      },
      {
        path: 'zaaktypes',
        component: CaseTypePage,
        meta: { title: 'Zaaktypes' },
      },
      {
        path: 'formulieren/:id',
        component: FormEditPage,
        meta: { title: 'Formulieren' },
      },
      {
        path: 'inkomensnormen',
        component: IncomeStandardsPage,
        meta: { title: 'Formulieren' },
      },
      {
        path: 'verenigingen',
        component: LegalAssociationsPage,
        meta: { title: 'Verenigingen' },
      },
    ],
  },
  { path: '/account', component: AccountPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.authToken) {
    return '/login';
  } else {
    if (auth.authToken && to.path === '/login') return '/';
    return true;
  }
});

router.beforeEach((to) => {
  const title = to.meta?.title ? `${to.meta.title} - Briefcase` : 'Briefcase';

  const envPrefixes = {
    development: '[DEV] ',
    staging: '[STG] ',
  };

  const envPrefix = envPrefixes[import.meta.env.MODE] || '';

  document.title = `${envPrefix}${title}`;
});

export default router;
