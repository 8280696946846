import { defineStore } from 'pinia';
import type { LegalCompany } from '@briefcase-app/briefcase-api-sdk';

export const useSharedStore = defineStore({
  id: 'sharedStore',
  state: () => ({
    currentCompany: {} as LegalCompany,
  }),
  persist: false,
});

export default useSharedStore;
