import { Module } from '../Module.class';
import { Ref } from 'vue';
import { AxiosProgressEvent } from 'axios';
import { Image } from '../../Models/image.model';
import { ApiResponse } from '../../Models/apiResponse.model';

export class Images extends Module {
  protected endpoint = '/images';

  constructor() {
    super();
  }

  public async upload(
    image: File,
    imageableId: string,
    imageableType: string,
    uploadProgress: Ref<number | null> | null = null,
  ): Promise<ApiResponse<Image>> {
    if (uploadProgress !== null) uploadProgress.value = null;

    const formData = new FormData();
    formData.append('file', image);
    formData.append('imageable_id', imageableId);
    formData.append('imageable_type', imageableType);

    const response = await this.client.post(this.endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (uploadProgress === null) return;
        uploadProgress.value = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total ?? 1) - 1,
        );
      },
    });

    return response.data;
  }
}
