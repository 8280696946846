import { RestModule } from '../RestModule.class';
import {
  IncomeStandard,
  NewIncomeStandard,
} from '../../Models/incomeStandard.model';

export class IncomeStandards extends RestModule<
  IncomeStandard,
  NewIncomeStandard
> {
  constructor() {
    super('income-standards');
  }
}
