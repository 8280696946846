import {
  LegalCompany,
  NewLegalCompany,
} from '../../Models/legalCompany/legalCompany.model';
import { RestModule } from '../RestModule.class';

export class LegalCompanies extends RestModule<LegalCompany, NewLegalCompany> {
  constructor() {
    super('legal-companies');
  }
}
