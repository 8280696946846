import { RestModule } from './RestModule.class';
import { ApiResponse } from '../Models/apiResponse.model';
import { NewPhoneNumber, PhoneNumber } from '../Models/phoneNumber.model';

export class PhoneNumbers extends RestModule<PhoneNumber, NewPhoneNumber> {
  constructor() {
    super('phone-numbers');
  }

  public async setPrimary(id: number): Promise<ApiResponse<PhoneNumber>> {
    return this.update(id, { is_primary: true });
  }
}
