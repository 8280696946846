<script setup lang="ts">
import { Alert } from '@briefcase-app/briefcase-lib';
import { VButton } from '@briefcase-app/briefcase-lib';
import {
  PhoneArrowDownLeftIcon,
  UserIcon,
  ArrowRightIcon,
} from '@heroicons/vue/16/solid';
import useCallEntryStore from '../../Stores/CallEntryStore';
import { computed, ref } from 'vue';

const callEntryStore = useCallEntryStore();
const callEntries = computed(() => callEntryStore.activeCalls);

const index = ref(0);
</script>

<template>
  <Alert v-if="callEntries.length > 0">
    <div class="flex flex-row items-center gap-2">
      <PhoneArrowDownLeftIcon class="h-4 w-4" />
      <p>{{ callEntries[index].call_from }}</p>
    </div>

    <div class="flex flex-row items-center gap-2">
      <UserIcon class="h-4 w-4" />
      <p>Onbekend</p>
    </div>

    <VButton @click="() => {}">
      <p>Case openen</p>
      <ArrowRightIcon class="h-4 w-4" />
    </VButton>
  </Alert>
</template>
