import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

interface ErrorResponse {
  message?: string;
}

interface ClientConfig extends AxiosRequestConfig {
  baseURL: string;
}

class AxiosClient {
  // eslint-disable-next-line no-use-before-define
  private static _instance: AxiosClient | null = null;
  private readonly axiosInstance: AxiosInstance;

  private constructor(config: ClientConfig) {
    this.axiosInstance = axios.create(config);
    this.setupInterceptors();
  }

  public static getInstance(): AxiosClient {
    if (!AxiosClient._instance) {
      throw new Error(
        "AxiosClient has not been initialized. Please call 'initialize' first.",
      );
    }
    return AxiosClient._instance;
  }

  public static initialize(config: ClientConfig): void {
    if (!AxiosClient._instance) {
      AxiosClient._instance = new AxiosClient(config);
    }
  }

  private setupInterceptors(): void {
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      this.handleError,
    );
  }

  private handleError(error: AxiosError<ErrorResponse>): Promise<never> {
    if (error.response) {
      const { status } = error.response;
      const errorMessage =
        error.response.data?.message ||
        error.response.statusText ||
        'An unknown error occurred';

      if (status === 401) {
        AxiosClient.getInstance().setAuthToken(null);
      }

      error.message = errorMessage;
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }

  public setAuthToken(token: string | null): void {
    if (token) {
      this.axiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
    } else {
      delete this.axiosInstance.defaults.headers.common['Authorization'];
    }
  }

  public get client(): AxiosInstance {
    return this.axiosInstance;
  }
}

export default AxiosClient;
