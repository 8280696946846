import { ref, provide, inject } from 'vue';

type EventHandler = (...args: unknown[]) => void;

class EventBus {
  private key;
  private bus = ref(new Map<string, EventHandler[]>());

  constructor(key = 'app') {
    this.key = `$${key}EventBus`;
  }

  emit(event: string, ...args: unknown[]) {
    const handlers = this.bus.value.get(event);
    if (handlers) {
      handlers.forEach((handler) => handler(...args));
    }
  }

  on(event: string, handler: EventHandler) {
    const handlers = this.bus.value.get(event) || [];
    handlers.push(handler);
    this.bus.value.set(event, handlers);
  }

  off(event: string, handler: EventHandler) {
    const handlers = this.bus.value.get(event);
    if (handlers) {
      this.bus.value.set(
        event,
        handlers.filter((h) => h !== handler),
      );
    }
  }
}

const provideEventBus = (key: string, eventBus: EventBus) => {
  provide(`$${key}EventBus`, eventBus);
};

export const createEventBus = (key = 'app') => {
  const eventBus = new EventBus(key);
  provideEventBus(key, eventBus);
  return eventBus;
};

export const useEventBus = (key = 'app') => {
  let eventBus = inject<EventBus>(`$${key}EventBus`);
  if (!eventBus && key !== 'app') {
    eventBus = inject<EventBus>('$appEventBus');
  }
  if (!eventBus) {
    throw new Error('EventBus not provided');
  }
  return eventBus;
};
